
.backgroundimage {
    min-height: 100%;
    // width: 105px;
    background-repeat: no-repeat;
    // background-repeat: round;
}

.img-card{
    width:150px;
 
}
.img-heading{
    // border-radius: 30px;
    border-top:1px solid rgba(81, 88, 98, 0.5); 
    color: black;
}
.card-imgs{
    min-height: 150px!important;
}
.imagePreview {
    border: 2px solid #555fe67a;
    min-height: 150px;
    border-radius: 16px;
    overflow: hidden;
}
.addcustomimage {
    
    color: transparent !important;
}
.up-btn{
    background-color: #696cff;
    color: #fff;
}
.breadcrumb{
    color: #515862;
}


.delete-icon {
    position: absolute;
    font-size: 16px;
    right: -5px;
    z-index: 1;
    top: 1px;
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #656de47a;
    color: #00000082;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #555fe67a;
        border-color: #555fe67a;
        color: #fff;
    }
    >i {
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        text-align: center;
        top: 8px;
    }
};

.icon-close{
    color: #fff;
    font-size: 34px;
}
.modal-title-name{
    color: #515862;
} 
.modal-title-caption{
    color: #515862;
} 
.color{
    background-color:#696cff;
} 

.black-card {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    color: white; /* Text color for better visibility */
    /* Add any other styles you want for the black card */
  }
  .backdrop{
    min-height: 150px;
    background-color:rgba(105, 108, 255, 0.5);
    color: #fff
  }
  .chekbos{
    margin-left: 13px;
  font-size: x-large;
  }
  .btn-image{
    border-radius: 20px;
    background-color: #696cff;
    color: #fff;
  }
.btn-image-outline{
    color: #696cff;
    border-radius: 30px;
    background-color: #fff;
    border:1px solid #696cff;
}