.search {
    border: 1px solid #E7E7E7;
    border-radius: 25px;
    // padding: 12px;
    background: #FFF;
    min-height: 44px;
    // width: 44px;
    border-color: 1px solid #515862;
}

.search-icon {
    top: 20%;
    right: 65%;
    color: #515862;
}
.search-style{
    padding-left: 25px;
}