.s-layout {
  display: flex;
  width: 100%;
}

.s-sidebar__trigger {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4em;
}

// .s-sidebar__trigger>i {
//   display: inline-block;
//   margin: 1.5em 0 0 1.5em;
//   color: #f07ab0;
// }

.s-sidebar__nav {
  position: fixed;
  top: 0;
  left: -15em;
  overflow: hidden;
  transition: all .3s ease-in;
  width: 15em;
  height: 100%;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7);
  overflow-y: auto;
  z-index: 1;
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus+.s-sidebar__nav,
.s-sidebar__trigger:hover+.s-sidebar__nav {
  left: 0;
  
}

// .s-sidebar__nav ul {
//   // position: absolute;
//   top: 4em;
//   left: 0;
//   margin: 0;
//   padding: 0;
//   width: 15em;
// }

// .s-sidebar__nav ul li {
//   width: 100%;
// }

.s-sidebar__nav-link {
  font-size: 16px !important;
  position: relative;
  display: inline-block;
  // width: 100%;
  height:3em;
  color: #515862;
  text-decoration: none;
  border:1px solid rgba(149, 150, 224, 0.5);;
  border-radius:30px
}

// .s-sidebar__nav-link em {
//   position: absolute;
//   top: 50%;
//   left: 4em;
//   transform: translateY(-50%);
// }

.s-sidebar__nav-link:hover {
  border:1px solid rgba(149, 150, 224, 0.5);
  background-color: rgba(149, 150, 224, 0.2); /* 20% opacity */
  border-radius: 30px;
  color: #696CFF;
}
.active{
  border:1px solid rgba(149, 150, 224, 0.5);

  border-radius: 30px;
  background: rgba(149, 150, 224, 0.2); /* 20% opacity */
  color: #696CFF;
  
}
.person-p{
  color: #696cff;
}

.nav-side{
  // background: rgba(149, 150, 224, 0.2); /* 20% opacity */
  // border-radius: 30px;
//     border: 1px solid rgba(149, 150, 224, 0.5); /* 50% opacity */ 
// color: #696CFF;
}

.s-sidebar__nav-link>i {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 4em;
  height: 4em;
}

.s-sidebar__nav-link>i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.sidebar-list-styled{
  cursor: pointer;
  min-height: 4em;
  display: flex;
  align-items: center;
  // padding: 0;

  &:hover{
    background: #F2F2F2;
  }
}


/* Mobile First */
@media (min-width: 42em) {
  .s-sidebar__trigger {
    width: 4em;
  }

  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }

}

@media (min-width: 68em) {
  .s-sidebar__trigger {
    display: none
  }

  .s-sidebar__nav {
    width: 15em;
  }

  .s-sidebar__nav ul {
    top: 1.3em;
  }
}

.sidebar-menu-fs {
  font-family: 'inter';
}

.nav-link[data-toggle].collapsed:after {
  content: "▾";
}

.nav-link[data-toggle]:not(.collapsed):after {
  content: "▴";
}

// ======Hide Scroll Bar========
.nav-scroll ::-webkit-scrollbar{
  display: none;
}
.accordion-button:focus{
  border-left: 3px solid #05B7E4;
  color: #05B7E4;
}
.accordion-button:not(.collapsed){
  color: #243641;
}

.s-sidebar__nav-link img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  max-width: 50px;
  /* Set a maximum width for the image */
  height: auto;
  /* Maintain the aspect ratio of the image */
  margin-right: 10px;
  /* Adjust spacing between the image and text */
}

.s-sidebar__nav-link h5 {
  /* Your existing styles for the heading text */

  /* Hide on mobile devices (up to 767px) */
  @media (max-width: 767px) {
    // display: none;
  }

  /* Hide on tablets (768px to 1023px) */
  @media (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
}