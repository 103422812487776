.card{
    background-color: #fff;
    border-radius: 15px;
    // min-height: 100vh;
}
.threecards {
    display: flex;
    // margin: 0px 10px
  }
  .card-body-first{
    border-radius: 30px;
    background-color: rgba(149, 150, 224, 0.5); /* 50% opacity */
    color: #fFF;
  }
  .card-body-test {
      background: #696CFF ;
      color: #fFF
  }
  .h-para, .h-num{
    color:#696cff
  }
  .card-body-sec{
      background: #fFF;
  }
  .card-body-thrd{
      background: #696CFF;
      color: #fff;
  }
  .card_btn{
      border-radius: 50%;
      padding: 4%;
      background-color:#fff;
      margin-bottom: 1rem !important;
  }
  .icon_btn{
      font-size: 1.5rem;
  }
  .card_font{
      color: #fff;
      font-size: 15px;
  }
  .card_btn_first{
    color: #fFF;
    background: #696CFF;
    border-radius: 50%;
    padding: 4%;
    margin-bottom: 1rem !important;
}
  .card_btn_sec{
      background: #70A1E5;
      border-radius: 50%;
      padding: 4%;
      margin-bottom: 1rem !important;
  }
  .card_btn_thrd{
    background: #696CFF;
    border-radius: 50%;
    padding: 4%;
    margin-bottom: 1rem !important;
}

  
  