.wrapper-layout {
    display: flex;
    width: 100%;
}

.wrapper-main {
    display: flex;
    flex: 1;
    margin-left: 15em;
    margin-top: 5em;
    overflow:hidden;
}

.wrapper-card {
    padding: 30px;
    width: 100%;
    min-height: 80vh;
}

@media (min-width: 566px) and (max-width:1024px) {
    .wrapper-main {
        margin-left: 0em;
    }
}

@media (min-width: 360px) and (max-width:565px) {
    .wrapper-main {
        margin-left: 0em;
    }
}