.card{
    background-color: #fff;
    border-radius: 15px;
    min-height: 100vh;
}
.threecards {
    display: flex;
    gap: 3px;
  }
  .card-body-first{
    cursor: pointer;
    border-radius: 30px;
    background-color:#fff!important;
    color: #696CFF;
    border: 1px solid  rgba(149, 150, 224, 0.5);
    height: 130px;
  }
  .card-body-first:hover{
    background-color:  rgba(149, 150, 224, 0.2) !important;
  }
  .card-body-test {
      background: #fFF ;
      color: #fFF
  }
  .h-para, .h-num{
    font-size: 20px;
    color:#696cff
  }
  .card-body-sec{
      background: #fFF;
  }

.person-l{
    color: #696CFF;
    font-size: 2rem;
}
  .icon_btn{
      font-size: 1.5rem;
  }
  .card_font{
      color: #fff;
      font-size: 15px;
  }

  
  